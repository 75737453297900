<template>
  <q-page>
    <UsersTable/>
  </q-page>
</template>

<script>
import UsersTable from '@/components/UsersTable.vue'
export default {
    name:"ManagementPage",
    components:{
    UsersTable,
},
    data(){
        return {
            
        }
    },
    methods:{
        
    },
    mounted(){
        this.$store.dispatch('db/getUser','')
    }
}
</script>

<style>

</style>