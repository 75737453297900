<template>

  <q-page>
    <!-- Welcome Message -->
    <q-card class="card q-pa-sm q-mx-auto q-mt-lg" v-if="!showQuestions">
        <q-banner v-if="submission">
            <template v-slot:avatar>
                <q-img v-if="submission.client?.profile?.length > 0" :src="submission.client.profile"/>
                <q-icon v-else name="person"/>
            </template>
            <h4>Welcome {{ clientName }}</h4>
            <div v-if="status === 'inactive'">
                <p>
                    Answer the questions with the first option that comes to mind.
                    Don't take too long to complete one question!
                </p>
                <p>
                    You have been registered by psychologist <i>{{ psychologist }}</i>
                </p>
                <p>Your psychotherapist will be notified when you complete and submit this test</p>
                <p>Your ticket is valid until <i>{{ expiryDate }}</i></p>
            </div>
            <div v-else-if="status === 'start' && unsetAnswers?.length > 0">
                You have answered {{ answerIds?.length }} / {{ questionIds?.length }}! <br>
                Continue when you're ready!
            </div>
            <div v-else-if="status === 'start'">
                You have completed your test <br>
                Submit when you're ready!
            </div>
            <div v-else-if="status === 'complete'">
                You have already completed this test. If you need a new ticket
                contact your psychologist <div class="text-primary">{{ psychologist }}</div>
                to consult the results.
            </div>
            
            <template v-slot:action v-if="status !== complete">
                <q-btn v-if="status === 'inactive'" outlined color="primary" label="Start Test" @click="start"/>
                <q-btn v-else-if="status === 'start' && unsetAnswers?.length === 0" label="Submit" @click="submitTest" outlined color="primary"/>
                <q-btn v-else outlined color="primary" label="Resume Test" @click="showQuestions = true"/>
            </template>
        </q-banner>
    </q-card>

    <!-- Banners & Alerts -->
     <q-banner 
        v-for="banner in banners" :key="banner.id"
        inline-actions
        :class="banner.status < 300 ? 'bg-primary' : banner.status < 500 ? 'bg-warning' : 'bg-negative'"
    >
        {{ banner.message }}
        <template v-slot:action>
            <q-btn flat icon="close" @click="removeBanner(banner.id)"/>
        </template>
     </q-banner>

    <!-- Test Form -->
    <div class="card q-pa-sm q-mx-auto q-mt-lg" v-if="submission && showQuestions">
        <q-linear-progress v-if="submission.answers" :value="progressPerc" color="accent" size="1.5rem" stripe>
            <div class="absolute-full flex flex-center">
                <q-badge color="white" text-color="accent" :label="progressLabel" />
            </div>
        </q-linear-progress>
        <div class="text-h3 q-mt-xl question text-center" v-if="answerIds?.length < questionIds?.length">{{ currQuestion.sq || currQuestion.default }}</div>
        <div class="text-h3 q-mt-xl question text-center" v-else>
            You have completed you Test! Click Submit to save.
        </div>
        <q-toolbar class="row justify-center q-gutter-sm" :class="$q.platform.is.mobile && 'fixed-bottom q-mb-xl'">
            <q-btn icon="chevron_left" @click="navigate(false)" v-if="unsetAnswers?.length > 0"/>
            <q-space/>
            <div class="row col-8 q-gutter-sm" v-if="unsetAnswers?.length > 0">
                <q-btn class="col" label="Yes" size="lg" @click="score(1)" :color="currAnswer === 1 ? 'primary':'grey-6'"/>
                <q-btn class="col" label="No" size="lg" @click="score(0)" :color="currAnswer === 0 ? 'primary':'grey-6'"/>
            </div>
            <q-btn class="col-8" label="Submit" v-else @click="submitTest"/>
            <q-space/>
            <q-btn icon="chevron_right" @click="navigate(true)" v-if="unsetAnswers?.length > 0"/>
        </q-toolbar>
    </div>
  </q-page>
  
</template>

<script>

import API from '@/services/api.service'
import { date } from 'quasar'

export default {
    name:'TestSubmitPage',

    data(){
        return {
            submission: {}, fetchError: null, token:'',
            showQuestions: false, currIndex:1, scoreValue:null,
            banners: [], bannerId: 0
        }
    },

    computed:{
        clientName(){return `${this.submission.client?.firstName} ${this.submission.client?.lastName}`},
        psychologist(){return `${this.submission.psychologist?.firstName} ${this.submission.psychologist?.lastName}`},
        expiryDate(){
            let dt = new Date(); dt.setTime(this.submission?.decoded?.exp * 1000);
            return date.formatDate(dt, 'DD/MM/YYYY')
        },
        status(){return this.submission.status},
        answerIds(){return this.submission.answers.map(item => {return item.index})},
        questionIds(){return this.submission.test.questions.list.map(item => {return item.index})},
        progressPerc(){return this.submission.answers?.length/this.submission.test?.questions?.list?.length},
        progressLabel() { return (this.progressPerc * 100).toFixed(2) + " %" },
        unsetAnswers(){return this.submission.test.questions.list.filter(item => !this.answerIds.includes(item.index))},
        currQuestion(){return this.submission.test.questions.list.find(item => item.index === this.currIndex)},
        currAnswer(){
            let ans = this.submission.answers.find(item => item.index === this.currIndex)
            if(ans)return ans.score
            return -1
        },
        apiHeader(){return {'x-access-token':this.token}},
    },

    methods:{
        
        start(){
            API.submitTest(this.submission._id, {
                status:'start', answers:[],
            }, this.token).then(res => {
                this.showQuestions = true
            }).catch(err => console.log(err.response.data))
        },
        submitTest(){
            API.submitTest(this.submission._id, {
                answers:this.submission.answers,
                status: 'complete'
            }, this.token).then(res => {
                alert('Your test application completed successfully!')
                localStorage.removeItem('submissionAnswers')
                localStorage.removeItem('submissionToken')
                this.$router.push('/')
            }).catch(err => console.log(err))
        },

        score(value){
            let ans = this.submission.answers.find(item => item.index === this.currIndex)
            if(ans)ans.score = value
            else this.submission.answers.push({index:this.currIndex, score:value})
            // if(this.currIndex <= this.questionIds.length)this.currIndex++
            if(this.unsetAnswers[0])this.currIndex = this.unsetAnswers[0].index
            else this.currIndex++
            localStorage.setItem('submissionAnswers', JSON.stringify(this.submission.answers))
            if(this.submission.answers?.length % 10 === 0)
                API.submitTest(this.submission._id, {answers:this.submission.answers}, this.token)
        },
        navigate(forward){
            if(forward){
                if(this.currIndex > this.questionIds.length)return
                if(!this.answerIds.includes(this.currIndex))return
                this.currIndex++
            }
            else {
                if(this.currIndex < 2)return
                this.currIndex--
            }
        },
        addBanner(data){
            if(!data.status || !data.message)return
            this.banners.push({
                id: ++this.bannerId,
                status: data.status,
                message: data.message
            })
        },
        removeBanner(id){
            this.banners = this.banners.filter(item => item.id != id)
        }
    },

    mounted(){
        let query = this.$route.query
        if(query.token){
            localStorage.setItem('submissionToken', query.token)
            this.token = query.token
        }
        else {
            let token = localStorage.getItem('submissionToken')
            if(!token){
                this.$router.push('/')
                // return
            }
            else {
                this.token = token
            }
        }
        if(this.token){
            API.confirmTicket(this.token).then(submission => {
                // console.log(submission)
                this.submission = submission
                if(!this.submission.answers)return
                let answers = []
                let localAnswers = localStorage.getItem('submissionAnswers')
                if(localAnswers){
                    answers = JSON.parse(localAnswers)
                    if(this.submission.answers?.length < answers?.length)
                        API.submitTest(this.submission._id, {answers}, this.token)
                }
                else {
                    answers = this.submission.answers
                    localStorage.setItem('submissionAnswers', JSON.stringify(answers))
                }
                if(this.unsetAnswers?.length > 0)this.currIndex = this.unsetAnswers[0].index
                else if(answers[answers?.length - 1].index)this.currIndex = answers[answers?.length - 1].index + 1
            }).catch(err => {
                console.log(err)
                this.addBanner(err)
            })
        }
    }
}
</script>
    
<style scoped>
    .card {max-width:50rem}
    .question {min-height:25rem}
</style>