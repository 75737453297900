let lastPage = localStorage.getItem('lastPage') || 'ledger'
let lastAccount = localStorage.getItem('lastAccount')  || ''

const app = {

    namespaced: true,

    state: {
        lastPage: lastPage,
        lastAccount: lastAccount,
        linearProgress: false,
    },

    mutations: {
        setLastPage(state, page){state.lastPage = page},
        setLastAccount(state, account){state.lastAccount = account},
        setLinearProgress(state, progress){state.linearProgress = progress},
    }
}

export default app