<template>
  <q-table :columns="Columns" :rows="filter" row-key="_id" selection="single" v-model:selected="selected">
    
    <template v-slot:top>
        <q-toolbar class="q-gutter-sm">
            <q-btn label="New Submission" icon="add" outline @click="showAddSubmit = true"/>
            <q-btn label="Del Submission" icon="remove" outline/>
            <q-space/>
            <q-toggle v-model="hideComletedSubmits" label="Hide Completed" color="warning" toggle-order="tf" left-label/>
            <q-input v-model="search" placeholder="search submissions" outlined>
                <template v-slot:prepend><q-icon name='search'/></template>
            </q-input>
        </q-toolbar>
    </template>

    <template v-slot:top-row v-if="showAddSubmit">

        <q-tr>
            <q-td></q-td>
            <q-td>
                <q-select flat v-model="newSubmit.psychologist" :options="psychologists" label="Psychologist" emit-value map-options/>
            </q-td>
            <q-td>
                <q-select flat v-model="newSubmit.client" :options="clients" label="Client" emit-value map-options/>
            </q-td>
            <q-td>
                <q-select flat v-model="newSubmit.test" :options="testOpt" label="Test" emit-value map-options/>
            </q-td>
            <q-td>
                <q-select flat v-model="newSubmit.status" :options="status" label="Status" emit-value map-options/>
            </q-td>
        </q-tr>
        <q-tr>
            <q-td colspan="100%" align="right" class="q-gutter-sm">
                <q-btn label="Cancel" color="negative" @click="showAddSubmit = false"></q-btn>
                <q-btn label="Save" color="primary" @click="save(true)"></q-btn>
                <q-btn label="Save & Add" color="primary" @click="save(false)"></q-btn>
            </q-td>
        </q-tr>
    </template>

    <template v-slot:body="props">
        <q-tr :props="props">
            <q-td>
                <q-checkbox v-model="props.selected"></q-checkbox>
            </q-td>
            <q-td>
                <q-select borderless dense v-model="props.row.psychologist._id" 
                    :options="psychologists" emit-value map-options/>
            </q-td>
            <q-td>
                <q-select borderless dense v-model="props.row.client._id" 
                    :options="clients" :disable="props.row.status == 'complete'"  emit-value map-options/>
            </q-td>
            <q-td auto-width>
                <q-select borderless dense v-model="props.row.test._id" 
                :options="testOpt" :disable="props.row.status == 'complete'"  emit-value map-options/>
            </q-td>
            <q-td auto-width>
                <q-select borderless dense v-model="props.row.status" :options="status" />
            </q-td>
            <q-td align="right" auto-width>
                <q-btn v-if="props.row.status != 'complete'" icon="attach_email" color="warning" dense flat @click="sendTicket(props.row._id)"/>
                <q-btn icon="assessment" color="accent" dense flat :to="`/testview?_id=${props.row._id}`"/>
                <q-btn v-if="props.row.status != 'complete'" icon="save" color="positive" dense flat @click="update(props.row)"/>
                <q-btn icon="delete" color="negative" dense flat @click="delSubmit(props.row)"/>
            </q-td>
        </q-tr>
    </template>
    
  </q-table>

  <div v-if="selected.length === 1">
    <div class="text-h4 q-mt-lg q-mb-md">Details</div>
    <div class="row q-gutter-sm" style="height:30rem">
        <q-card class="col-4">
            <q-input v-model="rawQuestions" type="textarea" autogrow @change="parseRawAnswer"/>
            <q-card-section>
                <q-btn icon="save"/>
            </q-card-section>
        </q-card>
        <q-card class="col-7">
            <q-table :columns="QuestCol" :rows="answers" row-key="index" :pagination="questPagination" wrap-cells>
                <template v-slot:top>
                    <q-toolbar>
                        <q-btn icon="save" label="Update" color="primary" @click="updateAnswer"/>
                    </q-toolbar>
                </template>
            </q-table>
        </q-card>
    </div>
  </div>
  
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { copyToClipboard } from 'quasar'
import API from '@/services/api.service'
export default {
    data(){
        return {
            Columns: [
                {label: 'Psychologist', field:'psychologist', align:'left'},
                {label: 'Client.', field:'client', align:'left'},
                {label: 'Test', field:'test', align:'left'},
                {label: 'Status', field:'status', align:'center'},
            ],
            status:['inactive', 'start', 'progress', 'complete'],
            search:'', selected:[], hideComletedSubmits:false,
            newSubmit:{
                psychologist:'', client:'', test:'', status:'inactive'
            }, showAddSubmit:false,
            rawQuestions:'', answers:[],
            QuestCol: [
                {label: 'No.', field:'index', align:'left'},
                {label:'Question', field:'question', align:'left'},
                {label:'Score', field:'score'},
            ],
            questPagination:{rowsPerPage: 20}
        }
    },
    computed:{
        ...mapState({
            submissions: state => state.db.submissions,
            users: state => state.db.users,
            tests: state => state.db.tests
        }),

        ...mapGetters({
            
        }),

        psychologists(){
            if(!this.users)return []
            return this.users.map(user => {
                if(user.role !== 'client')return {
                    label: `${user.firstName} ${user.lastName}`,
                    value: user._id
                }
            }) || []
        },

        clients(){
            if(!this.users)return []
            return this.users.map(user => {
                return {
                    label: `${user.firstName} ${user.lastName}`,
                    value: user._id
                }
            }) || []
        },

        testOpt(){
            if(!this.tests)return []
            return this.tests.map(test => {
                return {
                    label:test.abrv,
                    value:test._id
                }
            })
        },

        cellSelectOptions(field){
            switch(field){
                case 'psychologist': return this.psychologists
                case 'client': return this.client
                case 'test': return this.tests
                case 'status': return this.status
            }
            return []
        },
        
        filter(){
            let search = this.search
            return this.submissions.filter(item => {
                return (item.client.username.toLowerCase().includes(search.toLowerCase()) ||
                        item.psychologist.username.toLowerCase().includes(search.toLowerCase()) ||
                        item.test.title.toLowerCase().includes(search.toLowerCase()))
                        && (item.status !== 'complete' || !this.hideComletedSubmits)
            })
        },

        selectedTestQuestions(){
            if(this.selected.length === 0)return []
            let test = this.tests.find(item => item._id === this.selected[0].test._id)
            if(!test || !test.questions)return []
            return test.questions.list || []
        }
    },
    watch: {
        selected(newSelection, oldSelection){
            if(newSelection[0]){
                let questions = this.selectedTestQuestions
                this.answers = newSelection[0].answers.map(item => {
                    return {
                        ...item,
                        question: questions.find(q => q.index === item.index).sq
                    }
                })
            }
        },
        hideComletedSubmits(newOpt, oldOpt){localStorage.setItem('hideCompleted', newOpt)},
    },
    methods:{
    
        save(dismiss){
            this.$store.dispatch('db/createSubmission', this.newSubmit)
            .then(() => {
                this.newSubmit = {
                    psychologist:'', user:'', test:'', status:'inactive'
                }
            })
            if(dismiss)this.showAddSubmit = false
        },
        update(row){
            let submission = {
                client: row.client._id, psychologist:row.psychologist._id, test:row.test._id, status:row.status
            }
            this.$store.dispatch('db/updateSubmission', {
                id:row._id, data:submission, client:row.client
            })
        },
        delSubmit(submission){this.$store.dispatch('db/deleteSubmission', submission)},

        sendTicket(id){
            API.sendTicket(id).then(link => {
                
                copyToClipboard(link).then(()=>{
                    this.$q.notify('Link copied to Clipboard')
                }).catch(err => this.$q.notify(err.message))
            }).catch(err => console.log(err))
        },

        parseRawAnswer(){
            let questions = this.selectedTestQuestions
            if(!questions)return
            let data = JSON.parse(this.rawQuestions)
            this.answers = data.map(item => {
                let question = questions.find(q => q.index === item[0]) || ''
                return {
                    index:item[0],
                    question:question.sq,
                    score: item[1]
                }
            })
        },
        updateAnswer(){
            this.$store.dispatch('db/updateSubmission', {
                id:this.selected[0]._id,
                data:{answers:this.answers},
                client:this.selected[0].client.username
            })
        },
        showRow(props){console.log(props)}
    },
    mounted(){
        this.hideComletedSubmits = localStorage.getItem('hideCompleted') === 'true'
        if(this.users.length == 0)this.$store.dispatch('db/getUser', '')
        if(this.tests.length == 0)this.$store.dispatch('db/getTest', '')
    }
}
</script>

<style>

</style>