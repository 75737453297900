<template>
  <q-linear-progress v-if="fetchProgress" indeterminate color="accent" class=""/>
  <q-banner class="text-white bg-red" v-if="fetchError">
    {{ fetchError }}
    <template v-slot:action>
        <q-btn label="Dismiss" flat @click="fetchError = null"/>
        <q-btn label="Try Again" flat @click="fetchSubmission(urlParams)"/>
    </template>
  </q-banner>
  <q-banner v-else class="text-white bg-secondary q-mt-md" dense>
    <template v-slot:avatar>
        <q-avatar size="10rem">
            <img :src="submission.client.profile || 'https://cdn.quasar.dev/img/boy-avatar.png'">
        </q-avatar>
    </template>
    <div>
        <h4>{{ submission.client.firstName }} {{ submission.client.lastName }}</h4>
    <i>Interpret by {{ submission.psychologist.firstName }} {{ submission.psychologist.lastName }}</i><br>
    Test: {{ submission.test.title }} ({{ submission.test.abrv }})
    <p>Submitted On: {{ formatDate(submission.updatedAt) }}</p>
    Answered: {{ submission?.answers?.length }} / {{ submission?.test?.questions?.list?.length }}
    </div>
  </q-banner>

  <div class="row q-gutter-md q-mt-xl q-pl-lg">
        <!-- <LineChart v-if="subScales.length > 0" :chartData="subScalesChart" :options="lineOpt"/>
        <LineChart :chartData="validityChart" :options="lineOpt"/>
        <RadarChart :chartData="clinicalChart" :options="radarOpt"/> -->

        <!-- Validity Scale -->
        <apexchart
            :options="validityChart.options"
            :series="validityChart.series"
        ></apexchart>

        <!-- Validity Scale -->
        <apexchart
            :options="clinicalChart.options"
            :series="clinicalChart.series"
        ></apexchart>

        <!-- Validity Scale -->
        <apexchart
            :options="clinicalRadarChart.options"
            :series="clinicalRadarChart.series"
        ></apexchart>
    </div>

  <!-- <div class="text-h2 q-mx-auto text-center q-my-md q-mb-lg">{{ submission.test.title }}</div> -->
  <div class="q-mx-md q-px-sm">
    <!-- Tables -->
    <div class="">
        <div class="row q-gutter-sm" style="height:30rem">
            <!-- Cliend Card -->
            <q-card class="applicant-card col-2">
                <img :src="submission.client.profile"/>
                <q-card-section>
                    <div class="text-h6">{{ submission.client.firstName }} {{ submission.client.lastName }}</div>
                    <div class="text-subtitle2">{{ submission.psychologist.firstName }} {{ submission.psychologist.lastName }}</div>
                </q-card-section>
                <q-card-section>
                    <p>Test: {{ submission.test.abrv }}</p>
                    <i>{{ formatDate(submission.updatedAt) }}</i>
                </q-card-section>
            </q-card>

            <!-- Client Validity Summary -->
            <q-table :columns="validitySummaryCol" :rows="validitySummaryRows" row-key="abrv" v-model:expanded="scaleExpanded"
            virtual-scroll v-model:pagination="summaryPagination" :rows-per-page-options="[0]"
            :virtual-scroll-sticky-size-start="48" class="summaryTable col-4">
                <template v-slot:body="props">
                    <q-tr :props="props" :class="props.expand ? 'bg-accent text-white':''" 
                        @click="toggleExpanded(props.row.abrv)">
                        <q-td key="title">{{ props.row.title }}</q-td>
                        <q-td key="abrv" auto-width>{{ props.row.abrv }}</q-td>
                        <q-td key="tScore" align="right" auto-width>{{ props.row.tScore }}</q-td>
                    </q-tr>
                    <q-tr v-if="props.expand" class="bg-secondary text-white">
                        <q-td colspan="100%">
                            <q-list separator>
                            <q-item class="row q-gutter-sm" v-for="item in subScales" :key="item.abrv">
                                <div class="col">{{ item.title }}</div>
                                <div class="q-mr-lg">{{ item.abrv }}</div>
                                <div class="text-right">{{ item.tScore }}</div>
                            </q-item>
                        </q-list>
                        </q-td>
                        
                    </q-tr>
                    
                </template>
            </q-table>
            <!-- Client Clinical Summary -->
            <q-table :columns="validitySummaryCol" :rows="clinicalSummaryRows" row-key="abrv" v-model:expanded="scaleExpanded"
            virtual-scroll v-model:pagination="summaryPagination" :rows-per-page-options="[0]" wrap-cells
            :virtual-scroll-sticky-size-start="48" class="summaryTable col-5">
                <template v-slot:body="props">
                    <q-tr :props="props" :class="props.expand && 'bg-accent text-white'" @click="toggleExpanded(props.row.abrv)">
                        <q-td key="title">{{ props.row.title }}</q-td>
                        <q-td key="abrv" auto-width>{{ props.row.abrv }}</q-td>
                        <q-td key="tScore" align="right" auto-width>{{ props.row.tScore }}</q-td>
                    </q-tr>
                    
                    <q-tr v-if="props.expand" class="bg-secondary text-white">
                        <q-td colspan="100%">
                            <q-list separator>
                            <q-item class="row q-gutter-sm" v-for="item in subScales" :key="item.abrv">
                                <div class="col">{{ item.title }}</div>
                                <div class="q-mr-lg">{{ item.abrv }}</div>
                                <div class="text-right">{{ item.tScore }}</div>
                            </q-item>
                        </q-list>
                        </q-td>
                        
                    </q-tr>
                </template>
            </q-table>
        </div>
        <!-- Client Answers Details -->
        <div class="row q-mt-lg" v-if="selectedScaleAnswers">
            <q-table class="col" :rows="selectedScaleAnswers.trueList" title="True List" wrap-cells>
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td key="index" auto-width align="left">{{ props.row.index }}</q-td>
                        <q-td key="question">{{ props.row.question }}</q-td>
                        <q-td key="score" auto-width align="center">{{ props.row.score }}</q-td>
                    </q-tr>
                </template>
            </q-table>
            <q-table class="col" :rows="selectedScaleAnswers.falseList" title="False List" wrap-cells>
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td key="index" auto-width align="left">{{ props.row.index }}</q-td>
                        <q-td key="question">{{ props.row.question }}</q-td>
                        <q-td key="score" auto-width align="center">{{ props.row.score }}</q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>
    </div>
    
  </div>
  
</template>

<script>
import { mapState } from 'vuex'
import { date } from 'quasar'
import API from '@/services/api.service'
import mmpi from '@/utilities/mmpi'
import VueApexCharts from 'vue3-apexcharts'
// import { DoughnutChart, LineChart, RadarChart } from 'vue-chart-3'
// import { Chart, registerables } from 'chart.js'

// Chart.register(...registerables)



export default {
    name:'TestViewPage',

    // components: { LineChart, RadarChart },
    components: {
        apexchart: VueApexCharts
    },

    data(){
        return {
            submission:{client:{profile:''}, psychologist:{}, test:{}},
            fetchProgress:false, fetchError:null,
            summaryPagination:{rowsPerPage:0},
            radarOpt: {
                scales: {
                    r: {
                        ticks: {
                            beginAtZero:true, color:'red'
                        }, min:0,
                    }
                }
            },
            lineOpt: {
                scales: {
                    y: {
                        ticks: {steps:10},
                        min:0, 
                        grid: {
                            color: function(context){
                                if(context.tick.value > 70 || context.tick.value < 30)return 'red'
                                else if(context.tick.value > 60 || context.tick.value < 40)return 'orange'
                                else return 'green'
                            }
                        },
                        stacked:false
                    }
                },
                plugins:{
                    legend:{display:false}
                }
            },
            scaleExpanded:[], subScales:[],
        }
    },

    computed:{
        ...mapState({
            submissions: state => state.db.submissions,
            tests: state => state.db.tests,
        }),
        urlParams(){
            let url = "?"
            let query = this.$route.query
            for(const [key, val] of Object.entries(query)){
                url += key + "=" + val
            }
            return url
        },

        validityChart(){
            return {
                series: [
                    {
                        name: "T-score",
                        data: this.validitySummaryRows.map(item => {return item.tScore}),
                    }
                ],
                options: {
                    chart: {
                        width: 380, height: 350,
                        type: 'line'
                    },
                    title: {
                        text: 'Validity Scale', align: 'left'
                    },
                    xaxis: {
                        categories: this.validitySummaryRows.map(item => { return item.abrv })
                    },
                }
            }
        },

        subScalesChart(){
            return {
                labels: this.subScales.map(item => item.abrv),
                datasets: [
                    {
                        label:`subscales of ${this.scaleExpanded[0]}`,
                        data: this.subScales.map(item => item.tScore),
                        backgroundColor:'red'
                    },
                    ...mmpi.chartBounderies(this.subScales)
                ]
            }
        },

        clinicalChart(){
            return {
                series: [
                    {
                        name: "T-score",
                        data: this.clinicalSummaryRows.map(item => { return item.tScore })
                    }
                ],
                options: {
                    chart: {
                        width: 420, height: 350,
                        type: 'line'
                    },
                    title: {
                        text: 'Clinical Scale', align: 'left'
                    },
                    xaxis: {
                        categories: this.clinicalSummaryRows.map(item => { return item.abrv })
                    }
                }
            }
        },

        clinicalRadarChart(){
            return {
                labels: this.clinicalSummaryRows.map(item => {return item.abrv}),
                datasets: [
                    {
                        data: this.clinicalSummaryRows.map(item => {return item.tScore}),
                        fill:true,
                        backgroundColor: 'rgba(255, 99, 132, 0.2)',
                        borderColor: 'rgb(255, 99, 132)',
                    }
                ],
                series: [
                    {
                        name: "T-score",
                        data: this.clinicalSummaryRows.map(item => { return item.tScore })
                    }
                ],
                options: {
                    chart: {
                        height: 350, width: 350,
                        type: 'radar'
                    },
                    xaxis: {
                        categories: this.clinicalSummaryRows.map(item => { return item.abrv })
                    },
                    yaxis: {
                        stepSize: 10
                    }
                }
            }
        },

        validitySummaryCol(){
            return [
                {label:'Scale', field:'title', align:'left'},
                {label:'Abrv', field:'abrv', align:'left'},
                {label:'T-score', field:'tScore', align:'right'},
            ]
        },
        validitySummaryRows(){
            if(!this.submission.test._id)return[]
            let test = this.submission.test
            if(!test)return []
            return mmpi.getScaleResults(test.scale, this.submission.answers, this.submission.client.gender, mmpi.filters.validity)
        },
        clinicalSummaryRows(){
            if(!this.submission.test._id)return[]
            let test = this.submission.test
            if(!test)return []
            return mmpi.getScaleResults(test.scale, this.submission.answers, this.submission.client.gender, mmpi.filters.clinical)
        },
        selectedScaleAnswers(){
            if(!this.scaleExpanded[0])return false
            return this.getAnswers(this.scaleExpanded[0])
        },
        test(){
            return this.tests.find(item => item._id === this.submission.test._id)
        }
        
    },

    methods: {
        fetchSubmission(query){
            this.fetchProgress = true
            this.fetchError = null
            API.getSubmission(query).then(res => {
                console.log(res.data)
                this.submission = res.data[0] || null
                this.fetchProgress = false
            }).catch(err => {
                this.$q.notify({
                    message: err.response.data
                })
                this.fetchError = err.response.data
                this.fetchProgress = false
                console.error(err.response.data)
            })
        },

        getAnswers(abrv){
            let scale = this.submission.test.scale.find(item => item.abrv === abrv)
            if(!scale)return []
            let questions = this.submission.test.questions.list
            let answers = this.submission.answers.map(item => {
                let question = questions.find(quest => quest.index === item.index)
                question = question.sq || question.default || ''
                let score = item.score === 1 ? 'Yes':'No'
                return {
                    index: item.index,
                    question,
                    score
                }
            })
            let trueList = answers.filter(ans => scale.answers.trueList.includes(ans.index))
            let falseList = answers.filter(ans => scale.answers.falseList.includes(ans.index))
            return {
                trueList, falseList
            }
        },
        toggleExpanded(abrv){
            if(this.scaleExpanded[0] === abrv){
                this.scaleExpanded = []
                this.subScales = []
            }
            else {
                this.scaleExpanded = [abrv]
                this.subScales = this.getSubScales(abrv)
            }
        },
        getSubScales(abrv){
            let scale = this.submission.test.scale.find(item => item.abrv === abrv)
            if(!scale.subs)return []
            if(scale.type !== 'master' && !scale.subs)return[]
            console.log(scale)
            return mmpi.getScaleResults(this.submission.test.scale, this.submission.answers, this.submission.client.gender, scale.subs)
        },

        formatDate(dt){return date.formatDate(dt, 'DD/MM/YYYY')}
    },

    mounted(){
        this.fetchSubmission(this.urlParams)
    }
}
</script>

<style scoped>
    .applicant-card {
        max-width: 20rem;
    }
    .summaryTable {height:inherit}
</style>