<template>
  <q-page>
    <TestTable/>
  </q-page>
</template>

<script>
import TestTable from '@/components/TestTable.vue'
export default {
    name:"ManagementPage",
    components:{
    TestTable,
},
    data(){
        return {
            
        }
    },
    methods:{
        
    },
    mounted(){
        this.$store.dispatch('db/getTest','')
    }
}
</script>

<style>

</style>