<template>
  <!-- List of availble Tests -->
  <q-table :columns="Columns" :rows="filter" selection="multiple" v-model:selected="selected" @selection="showDetails">
    
    <template v-slot:top>

        <q-toolbar class="q-gutter-sm">
            <q-btn label="New Test" icon="add" outline @click="showAddTest = true"/>
            <q-btn label="Del Test" icon="remove" outline/>
            <q-space/>
            <q-input v-model="search" placeholder="search tests" outlined>
                <template v-slot:prepend><q-icon name='search'/></template>
            </q-input>
        </q-toolbar>
    </template>

    <template v-slot:top-row v-if="showAddTest">

        <q-tr>
            <q-td></q-td>
            <q-td>
                <q-input placeholder="title" v-model="newTest.title"/>
            </q-td>
            <q-td>
                <q-input placeholder="abrv" v-model="newTest.abrv"/>
            </q-td>
            <q-td>
                Total Questions
            </q-td>
            <q-td>
                Total Scales
            </q-td>
        </q-tr>
        <q-tr>
            <q-td colspan="100%" align="right" class="q-gutter-sm">
                <q-btn label="Cancel" color="negative" @click="showAddTest = false"></q-btn>
                <q-btn label="Save" color="primary" @click="save(true)"></q-btn>
                <q-btn label="Save & Add" color="primary" @click="save(false)"></q-btn>
            </q-td>
        </q-tr>
    </template>

    <template v-slot:body="props">
        <q-tr :props="props">
            <q-td>
                <q-checkbox v-model="props.selected"></q-checkbox>
            </q-td>
            <q-td v-for="cell in editableCol" :key="cell.field">
                {{ props.row[cell.field] }}
                <q-popup-edit v-model="props.row[cell.field]" v-slot="scope">
                    <q-input :placeholder="`${props.row[cell.label]}`" v-model="scope.value" @keyup.enter="scope.set" autofocus/>
                </q-popup-edit>
            </q-td>
            <q-td align="right" auto-width>
                {{ totalQuestions }}
            </q-td>
            <q-td align="right" auto-width>
                {{ totalScales }}
            </q-td>
            <q-td align="right" auto-width>
                <q-btn icon="save" color="positive" dense flat @click="update(props.row)"/>
                <q-btn icon="delete" color="negative" dense flat @click="delTest(props.row)"/>
            </q-td>
        </q-tr>
    </template>
    
  </q-table>

  <!-- Question Edit -->
  <q-table v-if="selected.length === 1" 
    :columns="QuestCol" :rows="questions" 
    row-key="index" bordered>
    <template v-slot:top>
        <div class="text-h5">Question Edit</div>
        <q-toolbar class="q-gutter-md">
            <q-file v-model="questionFile" label="question json ..." @update:model-value="parseFile" outlined>
                <template v-slot:prepend><q-icon name="file_upload"/></template>
            </q-file>
            <q-space/>
            <q-btn icon="save" color="primary" @click="saveQuestions" outline/>
            <q-input placeholder="search question..." v-model="questionSearch"/>
        </q-toolbar>
    </template>

    <template v-slot:body="props">
        <q-tr :props="props">
            <q-td key="index" auto-width>{{ props.row.index }}</q-td>
            <q-td key="default">
                {{ props.row.default }}
            </q-td>
            <q-td key="sq">
                {{ props.row.sq }}
                <q-popup-edit v-model="props.row.sq" v-slot="scope">
                    <q-input v-model="scope.value" dense autofocus @keyup.enter="scope.set" />
                </q-popup-edit>
            </q-td>
            <q-td auto-width align="right">
                <q-chip v-for="item in scaleHasQuest(props.row.index, true)" :key="item" 
                color="positive" text-color="white">{{ item.abrv }}<q-tooltip>{{ item.title }}</q-tooltip></q-chip>
            </q-td>
            <q-td auto-width align="right">
                <q-chip v-for="item in scaleHasQuest(props.row.index, false)" :key="item" 
                color="negative" text-color="white">{{ item.abrv }}<q-tooltip>{{ item.title }}</q-tooltip></q-chip>
            </q-td>
        </q-tr>
    </template>
  </q-table>

  <!-- Scale Edit -->
  <q-table v-if="selected.length === 1" :columns="ScaleCol" :rows="scales" row-key="abrv" 
    v-model:expanded="expandedScales" :filter="scaleSearch">
    <template v-slot:top>
        <div class="text-h5">Scale Edit</div>
        <q-toolbar class="q-gutter-md">
            <q-file v-model="scaleFile" label="scales json ..."
                @update:model-value="parseScaleFile" outlined>
                <template v-slot:prepend><q-icon name="file_upload"/></template>
            </q-file>
            <q-space/>
            <q-btn icon="save" color="primary" @click="saveScales" outline/>
            <q-input placeholder="search scales..." v-model="scaleSearch"/>
        </q-toolbar>
    </template>

    <template v-slot:body="props">
        <q-tr :props="props">
            <q-td auto-width>
                <q-toggle v-model="props.expand" checked-icon="add" unchecked-icon="remove" />
            </q-td>
            <q-td key="abrv" auto-width>
                {{ props.row.abrv }}
                <q-popup-edit v-model="props.row.abrv" v-slot="scope">
                    <q-input v-model="scope.value" dense autofocus @keyup.enter="scope.set" />
                </q-popup-edit>
            </q-td>
            <q-td key="title" auto-width>
                {{ props.row.title }}
                <q-popup-edit v-model="props.row.title" v-slot="scope">
                    <q-input v-model="scope.value" dense autofocus @keyup.enter="scope.set" />
                </q-popup-edit>
            </q-td>
            <q-td align="right">
                <div class="row">
                    <div class="row col justify-end" v-if="props.row.type === 'master'">
                        <q-chip v-for="item in props.row.subs" :key="item">{{item}}</q-chip>
                        <q-menu fit>
                            <q-input input-class="text-right q-px-md" @keyup.enter="setScaleSubs(props.row)" v-model="props.row.editSubs"
                                placeholder="add/remove sub scales" autofocus/>
                        </q-menu>
                    </div>
                    <q-space/>
                    <q-select v-show="props.row.type === 'sub'" :options="masterScales" 
                        v-model="props.row.master" @update:model-value="setMasterFromSub"/>
                    <q-toggle v-model="props.row.type" true-value="master" false-value="sub" :label="props.row.type" />
                </div>
            </q-td>
            <q-td key="mMean" auto-width>{{ props.row.statistics.mean.m }}</q-td>
            <q-td key="fMean" auto-width>{{ props.row.statistics.mean.f }}</q-td>
            <q-td key="mSD" auto-width>{{ props.row.statistics.sd.m }}</q-td>
            <q-td key="fSD" auto-width>{{ props.row.statistics.sd.f }}</q-td>
        </q-tr>
        <q-tr v-show="props.expand" :props="props">
            <q-td colspan="100%">
                <div class="row">
                    <div class="col">
                        <q-table :rows="trueListFromInput(props.row.answers.trueList)" wrap-cells>
                            <template v-slot:top>
                                <q-toggle v-model="props.row.answers.editTrue" indeterminate-value="not" label="Edit True List"/>
                                <q-space/>
                                <q-input v-if="props.row.answers.editTrue" v-model="props.row.answers.editList" autogrow clearable standout outlined dense
                                    placeholder="question items..." input-class="text-right" @keyup.enter="modifyScaleQuestionItems(props.row, true)"/>
                            </template>
                        </q-table>
                    </div>
                    <div class="col">
                        <q-table :rows="falseListFromInput(props.row.answers.falseList)" wrap-cells>
                            <template v-slot:top>
                                <q-toggle v-model="props.row.answers.editFalse" indeterminate-value="not" label="Edit False List"/>
                                <q-space/>
                                <q-input v-if="props.row.answers.editFalse" v-model="props.row.answers.editList" autogrow clearable standout outlined dense
                                    placeholder="question items..." input-class="text-right" @keyup.enter="modifyScaleQuestionItems(props.row, false)"/>
                            </template>
                        </q-table>
                    </div>
                </div>
            </q-td>
        </q-tr>
    </template>
  </q-table>
</template>

<script>
import API from '@/services/api.service'
import { mapState } from 'vuex'
export default {
    data(){
        return {
            Columns: [
                {label: 'Title', field:'title', align:'left'},
                {label: 'Abrv.', field:'abrv', align:'left'},
                {label: 'Total Questions', align:'left'},
                {label: 'Total Scales', align:'center'},
            ],
            QuestCol: [
                {label: 'Index', field:'index', align:'left'},
                { label: 'Default', field: 'default', align: 'left' },
                {label: 'Question', field:'sq', align:'left'},
                {label: 'Yes'},
                {label: 'No'},
            ],
            ScaleCol: [
                {label:'', align:'left'},
                {label:'Abrv', field:'abrv', align:'left'},
                {label:'Scale', field:'title', align:'left'},
                {label:'Details', align:'center'},
                {label:"Male's Mean", field:'mMean'},
                {label:"Female's Mean", field:'fMean'},
                {label:"Male's SD", field:'mSD'},
                {label:"Female's SD", field:'fSD'},
            ],
            roles:['admin', 'manager','psychologist','user'],
            search:'', selected:[], 
            questionFile:null, 
            questions:[], 
            questionSearch:'', 
            scaleFile:null, scales:[], scaleSearch:'', expandedScales:[],
            newTest:{
                title:'', abrv:''
            }, showAddTest:false,
        }
    },
    computed:{
        ...mapState({
            tests: state => state.db.tests
        }),
        
        filter(){
            let search = this.search
            return this.tests.filter(item => {
                return item.title.toLowerCase().includes(search.toLowerCase()) ||
                        item.abrv.toLowerCase().includes(search.toLowerCase())
            })
        },

        editableCol(){
            return this.Columns.filter(item => ['title', 'abrv'].includes(item.field))
        },

        totalQuestions(row){
            if(row && row.questions && row.questions.list)return row.questions.list.length
            return 0
        },
        totalScales(row){
            if(row && row.scale)return row.scale.length
            return 0
        },

        scaleTypes(){return ['master','sub']},
        scaleAbrvs(){return this.scales.map(item => {return item.abrv}) || []},
        masterScales(){return this.scales.filter(item => item.type === 'master').map(item => item.abrv)},
        
    },
    methods:{
    
        save(dismiss){
            this.$store.dispatch('db/createTest', this.newTest)
            .then(() => {
                this.newUser = {
                    title:'', abrv:''
                }
            })
            if(dismiss)this.showAddTest = false
        },
        update(test){
            
        },
        delTest(test){
            let delConfirm = confirm(`Do you want to delete ${test.title} test?`)
            if(delConfirm)this.$store.dispatch('db/deleteTest', test)
        },

        showDetails(details){
            // console.log(details.rows[0].questions)
            this.questions = details.rows[0].questions.list
            this.scales = details.rows[0].scale
        },
        saveQuestions(){
            this.$store.dispatch('db/updateTest', {
                id: this.selected[0]._id,
                data: {
                    questions: {
                        type: 'boolean',
                        list: this.questions
                    }
                }
            })
        },
        saveScales(){
            this.$store.dispatch('db/updateTest', {
                id: this.selected[0]._id,
                data: {
                    scale: this.scales
                }
            })
        },

        parseFile(e){
            API.parseQuestions(this.questionFile)
            .then(result => this.questions = result.data)
            .catch(err => console.log(err))
        },
        parseScaleFile(e){
            API.parseScales(this.scaleFile)
            .then(response => this.scales = response.data)
            .catch(err => console.log(err))
        },

        scaleHasQuest(index, direction){
            let scales = this.scales.filter(scale => {
                return direction ? scale.answers.trueList.includes(index) : scale.answers.falseList.includes(index)
            })
            return scales.map(scale => {
                return scale
            })
        },
        trueListFromInput(list){
            if(typeof list === 'object'){
                return this.questions.filter(quest => list.includes(quest.index)).map(quest => {return {index:quest.index, question:quest.sq}})
            }
            if(!list.includes(','))return []
            list = list.split(',')
            return this.questions.filter(quest => list.includes(quest.index.toString())).map(quest => {return {index:quest.index, question:quest.sq}})
        },
        falseListFromInput(list){ 
            if(typeof list === 'object'){
                return this.questions.filter(quest => list.includes(quest.index)).map(quest => {return {index:quest.index, question:quest.sq}})
            }
            if(!list.includes(','))return []
            list = list.split(',')
            return this.questions.filter(quest => list.includes(quest.index.toString())).map(quest => {return {index:quest.index, question:quest.sq}})
        },
        modifyScaleQuestionItems(row, trueList){
            let items = row.answers.editList.split(',').map(item => {return parseInt(item)})
            let list = trueList ? row.answers.trueList : row.answers.falseList
            items.map(item => {
                // remove item from list if it is negative e.g. -24
                if(item < 0)list = list.filter(l => l !== Math.abs(item))
                else if(item > 0){
                    if(!list.includes(item))list.push(item) // add if not in list
                }
            })
            delete row.answers.editList
            if(trueList)row.answers.trueList = list
            else row.answers.falseList = list
        },
        setScaleSubs(row){
            let subs = row.editSubs.split(',')
            let list = row.subs || []
            subs.map(sub => {
                if(sub.charAt(0) === '-'){
                    sub = sub.substring(1)
                    if(this.scaleAbrvs.includes(sub))list = list.filter(s => s !== sub)
                    let subScale = this.scales.find(item => item.abrv === sub)
                    if(subScale)subScale.master = row.abrv
                }
                else {
                    if(this.scaleAbrvs.includes(sub) && !list.includes(sub))list.push(sub)
                    let subScale = this.scales.find(item => item.abrv === sub)
                    if(subScale){
                        subScale.master = row.abrv
                        subScale.type = 'sub'
                    }
                }
            })
            row.subs = list
            row.editSubs = ''
        },
        
    },
    watch:{
        tests(){
            this.questions = this.tests[0].questions.list
            this.scales = this.tests[0].scale
            this.selected = [this.tests[0]]
        }
    },
    mounted(){
        if(this.tests.length === 0)this.$store.dispatch('db/getTest','')
    }
}
</script>

<style>

</style>