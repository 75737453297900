import axios from "axios"

const API_URL = process.env.VUE_APP_API
const headers = () => {
    let accessToken = localStorage.getItem('x-access-token')
    return {headers:{'x-access-token':accessToken}}
}

class API {

    // Authentication API
    login(user) {
        return axios.post(`${API_URL}/login`, 
        {
            username:user.username, 
            password:user.password
        })
    }

    logout(){
        localStorage.removeItem('x-access-token')
    }

    refresh(){
        return axios.get(`${API_URL}/auth/refresh`, headers())
    }

    // User API
    createUser(data){return axios.post(`${API_URL}/user`, data, headers())}
    getUser(query){return axios.get(`${API_URL}/user${query}`, headers())}
    updateUser(payload){return axios.put(`${API_URL}/user/${payload.id}`, payload.data, headers())}
    deleteUser(id){return axios.delete(`${API_URL}/user/${id}`, headers())}

    // Test API
    createTest(data){return axios.post(`${API_URL}/test`, data, headers())}
    getTest(query){return axios.get(`${API_URL}/test${query}`, headers())}
    updateTest(payload){return axios.put(`${API_URL}/test/${payload.id}`, payload.data, headers())}
    deleteTest(id){return axios.delete(`${API_URL}/test/${id}`, headers())}

    parseQuestions(file) {
        const fileData = new FormData()
        fileData.append('file', file)
        return axios.post(
            `${API_URL}/test/questions/parse`,
            fileData,
            headers()
        )
    }
    parseScales(file) {
        const fileData = new FormData()
        fileData.append('file', file)
        return axios.post(
            `${API_URL}/test/scales/parse`,
            fileData,
            headers()
        )
    }

    // Submission API
    createSubmission(data){return axios.post(`${API_URL}/submit`, data, headers())}
    getSubmission(query){return axios.get(`${API_URL}/submit${query}`, headers())}
    updateSubmission(payload){return axios.put(`${API_URL}/submit/${payload.id}`, payload.data, headers())}
    deleteSubmission(id){return axios.delete(`${API_URL}/submit/${id}`, headers())}

    sendTicket(id){
        return axios.get(`${API_URL}/auth/submission/token?id=${id}`, headers()).then(res => {
            let link = `${process.env.VUE_APP_BASE_URL}/#/submit?token=${res.data}`
            return Promise.resolve(link)
        }).catch(err => {return Promise.reject(err)})
    }
    confirmTicket(token){
        return axios.get(`${API_URL}/auth/submission/token/verify?token=${token}`)
        .then(res => {
            return Promise.resolve(res.data)
        }).catch(err => {return Promise.reject(err.response.data)})
    }
    submitTest(id, data, token){
        console.log('submit test', id, data, token)
        return axios.put(`${API_URL}/submit/${id}`, data, {headers:{'x-access-token':token}})
            .then(res => {return Promise.resolve(res.data)})
            .catch(err => {return Promise.reject(err)})
    }

}

export default new API()