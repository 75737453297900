import API from '../services/api.service'
import Notify from 'quasar/src/plugins/Notify.js';

const user = JSON.parse(localStorage.getItem('user'))

const auth = {
    namespaced: true,
    
    state: {
        user: user,
        authorized: false
    },

    actions: {

        login({ commit }, user) {
            return API.login(user).then(res => {
                // console.log(user, res.data)
                localStorage.setItem('x-access-token', res.data.token)
                localStorage.setItem('user', JSON.stringify(res.data))
                commit('loginSuccess', res.data)
                return Promise.resolve(res.data)
            })
            .catch(error => {
                console.log(error)
                commit('logout')
                localStorage.removeItem('x-access-token')
                localStorage.removeItem('user')
                return Promise.reject(error)
            })
        },

        refreshToken({ commit }) {
            return API.refresh().then(res => {
                // console.log(res.data)
                localStorage.setItem('x-access-token', res.data)
                commit('setAuth', true)
                return Promise.resolve(res.data)
            })
            .catch(error => {
                console.log(error.response.status, error)
                commit('setAuth', false)
                return Promise.reject(error)
            })
        },


    },

    mutations: {
        
        loginSuccess(state, user) {
            state.user = user
            state.authorized = true
            // console.log('loginsuccess', state.user, state.authorized)
        },

        logout(state) {
            localStorage.removeItem('user')
            localStorage.removeItem('x-access-token')
            state.user = null
            state.authorized = false
        },

        setAuth(state, auth) {
            state.authorized = auth
        },
    },

    getters: {
        initials: state => {
            if(state.user && state.user.firstName && state.user.lastName){
                return `${state.user.firstName.charAt(0)}${state.user.lastName.charAt(0)}`
            }
            else return 'XX'
        },
    }
}

export default auth