<template>
  <q-page>
    <SubmissionTable/>
  </q-page>
</template>

<script>
import SubmissionTable from '@/components/SubmissionTable.vue'
export default {
    name:"ManagementPage",
    components:{
    SubmissionTable
},
    data(){
        return {
            
        }
    },
    methods:{
        
    },
    mounted(){
        this.$store.dispatch('db/getSubmission','')
    }
}
</script>

<style>

</style>